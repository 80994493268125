.card {
  width: 430px;
}

.saveButton {
  width: 100%;
}

.deleteButton {
  cursor: pointer;
}

.form {
  .marginBottom0 {
    margin-bottom: 0;

    & > div > div {
      margin-bottom: 0.5rem;
    }
  }

  & > div {
    margin-bottom: 0.5rem;
  }
}
