.card {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  margin: 0rem 0.3rem 0.3rem 0;
}

.delete {
  margin-left: 0.5rem;
  cursor: pointer;

  svg {
    color: #ff4d4f;
  }
}
