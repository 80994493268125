.addZone {
  display: flex;
  margin: 1rem 0;

  button {
    padding: 0 0.8rem;
    margin-left: 1rem;
  }
}

.characters {
  display: grid;
  grid-template: auto / 460px 460px 460px;

  > div {
    margin-bottom: 30px;
  }
}
