.form {
  & > div {
    margin-bottom: 0.5rem;
  }
}

.saveButton {
  width: 100%;
  margin-top: 1rem;
}
