.layout {
  min-height: 100vh;

  .logo {
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
