.container {
  background: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template: 1fr 16.5rem 1fr / 1fr 40% 1fr;
  img {
    grid-area: 1 / 2 / 1 / 2;
    align-self: center;
    justify-self: center;
  }
}
