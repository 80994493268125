@import '~antd/dist/antd.css';

#root {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

:root {
  font-size: 14px;
}
