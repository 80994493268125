.action {
  color: #1890ff;
  cursor: pointer;
}

.editableRow:hover div[class^='EditableCell_editableCellValueWrap'] {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}
