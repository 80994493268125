.saveButton {
  width: 100%;
  margin-top: 1rem;
}

.form {
  & > div {
    margin-bottom: 0.5rem;
  }

  .label {
    color: rgba(0, 0, 0, 0.85);
  }
}

.addUser {
  color: #1890ff;
  cursor: pointer;
}

.userTeamsContainer {
  display: flex;
  flex-wrap: wrap;
}
